


















































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import PASSWORD_SET from '@/graphql/mutations/PasswordSet.graphql';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { PasswordSetMutationVariables } from '@/types/schema';
import { GraphQLError } from 'graphql';

export default Vue.extend({
  name: 'ResetPassword',
  components: {
    AppMain,
    AppForm,
  },
  beforeRouteEnter(to, _from, next) {
    const { token } = to.query;
    if (!token) return next({ name: 'login' });
    return next();
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        password: '',
        passwordConfirm: '',
      },
    };
  },
  validations() {
    return {
      form: {
        password: { required, minLength: minLength(6) },
        passwordConfirm: { required, sameAsPassword: sameAs('password') },
      },
    };
  },
  methods: {
    validationMessages,
    async resetPassword() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: PASSWORD_SET,
          variables: {
            input: {
              token: this.$route.query.token,
              password: this.form.password,
            },
          } as PasswordSetMutationVariables,
        });
        this.$router.push({ name: 'login', query: { reset: '1' } });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
});
